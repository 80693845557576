<template>
  <div class="barcode-input">
    <div class="device-container">
      <h4>Device IMEI</h4>
      <h4 v-if="useIccd">SIM ICCD</h4>
      <h4 v-if="!useIccd">Mobile Number</h4>
    </div>
    <div v-for="(device, idx) of devices" v-bind:key="idx" class="device-container" ref="deviceInputs">
      <div class="col" :class="{ 'form-error': $v.devices.$each[idx].device_imei.$error }" name="imeiCol">
        <b-input-group name="imeiGroup">
          <b-form-input autocomplete="off" v-model="$v.devices.$each[idx].device_imei.$model"
                        @keypress="inputPress($event, idx)"
                        @blur="_validateImei(device)"
                        placeholder="Device IMEI"
                        name="imeiInput"></b-form-input>
          <b-input-group-append v-if="validateImeis">
            <i class="icon-button row-icon-button" :class="$config.icons.general.unknown"
               v-b-tooltip title="Incomplete" v-if="device.is_valid===null"></i>
            <i class="icon-button row-icon-button-danger" :class="$config.icons.general.failure"
               v-b-tooltip title="Invalid IMEI" v-if="device.is_valid===false"></i>
            <i class="icon-button row-icon-button" :class="$config.icons.general.success"
               v-b-tooltip title="Valid IMEI" v-if="device.is_valid"></i>
          </b-input-group-append>
        </b-input-group>
        <div class="input-error" v-if="!$v.devices.$each[idx].device_imei.required">Field is Required</div>
        <div class="input-error" v-if="!$v.devices.$each[idx].device_imei.maxLength || !$v.devices.$each[idx].imei.minLength">
          IMEI Must be 15 Characters</div>
        <div class="input-error" v-if="device.is_valid===false">Device not Found</div>
      </div>
      <div class="col" :class="{ 'form-error': $v.devices.$each[idx].phone.$error }" name="phoneCol">
        <div class="phone-container">
          <b-form-input autocomplete="off" v-model="$v.devices.$each[idx].phone.$model"
                        debounce="500"
                        placeholder="Mobile Number or ICCD"
                        @keypress="inputPress($event, idx)"
                        @blur="validatePhone(device)"
                        name="phoneInput"
                        :state="null"
          ></b-form-input>
          <i class="fa row-action row-icon-button-danger" :class="$config.icons.general.alert"
             v-if="validateSim && device.phone && device.sim_id === null"
             v-b-tooltip.hover title="SIM Not Found"
             @click="devices.splice(idx, 1)"></i>
          <i class="fa row-action row-icon-button row-icon-button-warning" :class="$config.icons.general.alert"
             v-if="validateSim && device.phone && device.sim_id && device.sim_assigned"
             v-b-tooltip.hover :title="'SIM Already Assigned to ' + device.sim_assigned"
             @click="devices.splice(idx, 1)"></i>
          <i class="fa row-action row-icon-button" :class="$config.icons.general.success"
             v-if="validateSim && device.phone && device.sim_id && !device.sim_assigned"
             v-b-tooltip.hover title="SIM Found"
             @click="devices.splice(idx, 1)"></i>
        </div>
        <div class="input-error" v-if="!$v.devices.$each[idx].phone.required">Field is Required</div>
        <div class="input-error" v-if="!$v.devices.$each[idx].phone.simExists">
          SIM Card Not Found in System</div>
      </div>
      <div class="button-container">
        <i class="fa fa-times-circle row-icon-button-danger row-action"
           v-b-tooltip.hover title="Delete"
           v-if="idx > 0"
           @click="devices.splice(idx, 1)"></i>
      </div>
    </div>
  </div>
</template>

<script>

import { minLength, maxLength, requiredIf } from 'vuelidate/lib/validators'
import * as DataProvider from '@/components/helpers/DataProvider'
export default {
  name: 'admin-barcode-input',
  components: {
  },
  props: {
    validateImeis: Boolean,
    useIccd: Boolean,
    validateSim: Boolean
  },
  data: function () {
    return {
      devices: [
        {
          imei: '',
          phone: '',
          is_valid: null,
          sim_id: null
        },
        {
          imei: '',
          phone: '',
          is_valid: null,
          sim_id: null
        },
        {
          imei: '',
          phone: '',
          is_valid: null,
          sim_id: null
        }
      ]
    }
  },
  validations () {
    return {
      devices: {
        $each: {
          imei: {
            requiredIf: requiredIf(function (device) {
              return device.device_imei.length > 0
            }),
            minLength: minLength(15),
            maxLength: maxLength(15)
          },
          phone: {
            requiredIf: requiredIf(function (device) {
              return device.deice_imei.length > 0
            }),
            simExists: (phoneNumber) => {
              if (phoneNumber.length > 0 && this.validateSim) {
                let device = this.devices.find(x => x.phone === phoneNumber)
                console.log(device)
                console.log(this.validateSim)
                if (device) {
                  return (phoneNumber.length > 0 && device.sim_id !== null)
                }
              }
              return true
            }
          }
        }
      }
    }
  },
  async created () {

  },
  methods: {
    // Watch input and move to next field when we reach 15 characters.
    inputDeviceIMEI: function (event, idx) {
      if (event.length === 15) {
        let next = idx + 1
        if (next < this.devices.length) {
          this.$refs.deviceInputs[next].children.namedItem('imeiCol').children.namedItem('imeiGroup').children.namedItem('imeiInput').focus()
        } else {
          this.devices.push({
            imei: '',
            phone: '',
            is_valid: null
          })
          this.$nextTick(() => {
            this.$refs.deviceInputs[next].children.namedItem('imeiCol').children.namedItem('imeiGroup').children.namedItem('imeiInput').focus()
          })
        }
      }
    },
    _validateImei: async function (device) {
      if (this.validateImeis) {
        if (device.device_imei.length === 15) {
          let result = await DataProvider.getDeviceByIMEI(device.device_imei)
          if (result.success) {
            device.is_valid = true
          } else {
            device.is_valid = false
          }
        } else {
          device.is_valid = null
        }
      }
    },
    validate: function () {
      this.devices.forEach((device, idx) => {
        if (device.device_imei.length > 0 && !this.hideEmail) {
          this.$v.devices.$each[idx].phone.$touch()
        }
      })
      return !(this.$v.$anyError || (this.devices.some(x => x.is_valid === false && this.validateIMEIs)))
    },
    inputPress: function (event, idx) {
      console.log(event)
      if (event.charCode === 13) {
        let next = idx + 1
        if (event.target.name === 'imeiInput') {
          this.$refs.deviceInputs[idx].children.namedItem('phoneCol').children.namedItem('phoneInput').focus()
        } else if (next < this.devices.length) {
          this.$refs.deviceInputs[next].children.namedItem('imeiCol').children.namedItem('imeiGroup').children.namedItem('imeiInput').focus()
        }
      }
    },
    validatePhone: async function (device) {
      console.log('Validate Phone: ', device.phone)
      if (this.validateSim && device.phone.length > 0) {
        let result
        if (this.useIccd) {
          result = await DataProvider.adminGetSIMByICCID(device.phone)
        } else {
          result = await DataProvider.adminGetSIMByMSISDN(device.phone)
        }
        if (result.data.result) {
          device.sim_id = result.data.result.sim_id
          device.sim_assigned = result.data.result.device_imei
        }
      }
    }
  },
  watch: {
    devices: function (newval) {
      if (this.devices.length === 0) {
        this.devices.push({
          imei: '',
          phone: '',
          is_valid: null,
          sim_id: null
        })
      }
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../variables';

.barcode-input{
  position: relative;
}

.device-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}

.button-container {
  width: 1em;
  font-size: 1.5em;
}

.phone-container {
  display: flex;
  flex-direction: row
}

input {
  margin-right: 1em;
}

</style>

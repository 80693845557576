var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "barcode-input" },
    [
      _c("div", { staticClass: "device-container" }, [
        _c("h4", [_vm._v("Device IMEI")]),
        _vm.useIccd ? _c("h4", [_vm._v("SIM ICCD")]) : _vm._e(),
        !_vm.useIccd ? _c("h4", [_vm._v("Mobile Number")]) : _vm._e()
      ]),
      _vm._l(_vm.devices, function(device, idx) {
        return _c(
          "div",
          {
            key: idx,
            ref: "deviceInputs",
            refInFor: true,
            staticClass: "device-container"
          },
          [
            _c(
              "div",
              {
                staticClass: "col",
                class: {
                  "form-error": _vm.$v.devices.$each[idx].device_imei.$error
                },
                attrs: { name: "imeiCol" }
              },
              [
                _c(
                  "b-input-group",
                  { attrs: { name: "imeiGroup" } },
                  [
                    _c("b-form-input", {
                      attrs: {
                        autocomplete: "off",
                        placeholder: "Device IMEI",
                        name: "imeiInput"
                      },
                      on: {
                        keypress: function($event) {
                          return _vm.inputPress($event, idx)
                        },
                        blur: function($event) {
                          return _vm._validateImei(device)
                        }
                      },
                      model: {
                        value: _vm.$v.devices.$each[idx].device_imei.$model,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.$v.devices.$each[idx].device_imei,
                            "$model",
                            $$v
                          )
                        },
                        expression: "$v.devices.$each[idx].device_imei.$model"
                      }
                    }),
                    _vm.validateImeis
                      ? _c("b-input-group-append", [
                          device.is_valid === null
                            ? _c("i", {
                                directives: [
                                  { name: "b-tooltip", rawName: "v-b-tooltip" }
                                ],
                                staticClass: "icon-button row-icon-button",
                                class: _vm.$config.icons.general.unknown,
                                attrs: { title: "Incomplete" }
                              })
                            : _vm._e(),
                          device.is_valid === false
                            ? _c("i", {
                                directives: [
                                  { name: "b-tooltip", rawName: "v-b-tooltip" }
                                ],
                                staticClass:
                                  "icon-button row-icon-button-danger",
                                class: _vm.$config.icons.general.failure,
                                attrs: { title: "Invalid IMEI" }
                              })
                            : _vm._e(),
                          device.is_valid
                            ? _c("i", {
                                directives: [
                                  { name: "b-tooltip", rawName: "v-b-tooltip" }
                                ],
                                staticClass: "icon-button row-icon-button",
                                class: _vm.$config.icons.general.success,
                                attrs: { title: "Valid IMEI" }
                              })
                            : _vm._e()
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                !_vm.$v.devices.$each[idx].device_imei.required
                  ? _c("div", { staticClass: "input-error" }, [
                      _vm._v("Field is Required")
                    ])
                  : _vm._e(),
                !_vm.$v.devices.$each[idx].device_imei.maxLength ||
                !_vm.$v.devices.$each[idx].imei.minLength
                  ? _c("div", { staticClass: "input-error" }, [
                      _vm._v(" IMEI Must be 15 Characters")
                    ])
                  : _vm._e(),
                device.is_valid === false
                  ? _c("div", { staticClass: "input-error" }, [
                      _vm._v("Device not Found")
                    ])
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "col",
                class: { "form-error": _vm.$v.devices.$each[idx].phone.$error },
                attrs: { name: "phoneCol" }
              },
              [
                _c(
                  "div",
                  { staticClass: "phone-container" },
                  [
                    _c("b-form-input", {
                      attrs: {
                        autocomplete: "off",
                        debounce: "500",
                        placeholder: "Mobile Number or ICCD",
                        name: "phoneInput",
                        state: null
                      },
                      on: {
                        keypress: function($event) {
                          return _vm.inputPress($event, idx)
                        },
                        blur: function($event) {
                          return _vm.validatePhone(device)
                        }
                      },
                      model: {
                        value: _vm.$v.devices.$each[idx].phone.$model,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.$v.devices.$each[idx].phone,
                            "$model",
                            $$v
                          )
                        },
                        expression: "$v.devices.$each[idx].phone.$model"
                      }
                    }),
                    _vm.validateSim && device.phone && device.sim_id === null
                      ? _c("i", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          staticClass: "fa row-action row-icon-button-danger",
                          class: _vm.$config.icons.general.alert,
                          attrs: { title: "SIM Not Found" },
                          on: {
                            click: function($event) {
                              return _vm.devices.splice(idx, 1)
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.validateSim &&
                    device.phone &&
                    device.sim_id &&
                    device.sim_assigned
                      ? _c("i", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          staticClass:
                            "fa row-action row-icon-button row-icon-button-warning",
                          class: _vm.$config.icons.general.alert,
                          attrs: {
                            title:
                              "SIM Already Assigned to " + device.sim_assigned
                          },
                          on: {
                            click: function($event) {
                              return _vm.devices.splice(idx, 1)
                            }
                          }
                        })
                      : _vm._e(),
                    _vm.validateSim &&
                    device.phone &&
                    device.sim_id &&
                    !device.sim_assigned
                      ? _c("i", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover",
                              modifiers: { hover: true }
                            }
                          ],
                          staticClass: "fa row-action row-icon-button",
                          class: _vm.$config.icons.general.success,
                          attrs: { title: "SIM Found" },
                          on: {
                            click: function($event) {
                              return _vm.devices.splice(idx, 1)
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                !_vm.$v.devices.$each[idx].phone.required
                  ? _c("div", { staticClass: "input-error" }, [
                      _vm._v("Field is Required")
                    ])
                  : _vm._e(),
                !_vm.$v.devices.$each[idx].phone.simExists
                  ? _c("div", { staticClass: "input-error" }, [
                      _vm._v(" SIM Card Not Found in System")
                    ])
                  : _vm._e()
              ]
            ),
            _c("div", { staticClass: "button-container" }, [
              idx > 0
                ? _c("i", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.hover",
                        modifiers: { hover: true }
                      }
                    ],
                    staticClass:
                      "fa fa-times-circle row-icon-button-danger row-action",
                    attrs: { title: "Delete" },
                    on: {
                      click: function($event) {
                        return _vm.devices.splice(idx, 1)
                      }
                    }
                  })
                : _vm._e()
            ])
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }